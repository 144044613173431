import styled from "styled-components";
import Container from 'react-bootstrap/Container';

const isMobile = () => {
  // You can adjust this breakpoint to suit your needs
  return window.innerWidth <= 992; // Example breakpoint at 768 pixels
};

export const MainContainer = styled(Container)`
    padding: ${({ mobile }) => (mobile || isMobile() ? '5rem 2rem' : '5rem 5rem')};
`;


export const BigContainer = styled(Container)`
    padding: 2rem;
`
