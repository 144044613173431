import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import BackgroundPic1 from '../../../../assets/landing/hero1.jpg';
import BackgroundPic2 from '../../../../assets/landing/hero2.jpg';
import BackgroundPic3 from '../../../../assets/landing/hero3.jpg';
import { ReactComponent as Logo } from "../../../../assets/logo.svg";

const HeroContainer = styled.div`
  height: 75vh;
  overflow: hidden;
  padding: 0;

`;

const Slideshow = styled.div`
  position: relative;
  width: 100%;
  height: 50vh; /* Adjust the height as needed */
  overflow: hidden;
  display: flex;
  justify-content: space-between;
`;

const Slide = styled.div`
  flex: 0 0 33.33%;
  position: relative;
  overflow: hidden;
  transform: ${props => (props.active == "true" ? 'scale(1.5)' : 'scale(0.75)')};
  transition: transform 0.5s;
  z-index: ${props => (props.active == "true" ? 1 : 0)};
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const LogoContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0.3;
  bottom: 0;
`;

const BackgroundBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${props => props.activeimage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(8px);
  z-index: -1;
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 20%);
`;

const images = [BackgroundPic1, BackgroundPic2, BackgroundPic3];

class HeroSection extends React.Component {
  state = {
    currentSlide: 0
  };

  componentDidMount() {
    this.interval = setInterval(this.nextSlide, 4000); // Change image every 5 seconds (adjust as needed)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nextSlide = () => {
    this.setState(prevState => ({
      currentSlide: (prevState.currentSlide + 1) % images.length
    }));
  }

  render() {
    const { currentSlide } = this.state;

    const slides = [
      images[(currentSlide - 1 + images.length) % images.length],
      images[currentSlide],
      images[(currentSlide + 1) % images.length],
    ];

    return (
      <HeroContainer>
        <BackgroundBlur activeimage={images[currentSlide]} />
        <Container style={{height: '100%', padding: 0, overflow: 'hidden', position: 'relative'}}>
          <Row style={{height: '100%', display:"flex", justifyContent:"center", alignItems:"center"}}>
  
            <Col>
              <Slideshow>
                {slides.map((image, index) => (
                  <Slide key={index} active={(index === 1).toString()}>
                    <SlideImage src={image} alt={`Image ${index + 1}`} />
                  </Slide>
                ))}
              </Slideshow>
              
            </Col>
            <LogoContainer>
                <Logo />
              </LogoContainer>
          </Row>
        </Container>
      </HeroContainer>
    );
  }
}

export default HeroSection;
