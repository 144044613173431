import acousticCeiling from '../assets/services/Accoustical Ceilings.jpg';
import specialtCeiling from '../assets/services/Specialty Cielings.jpg';
import drywall from '../assets/services/drywall finishing.jpg';
import metalFraming  from '../assets/services/metal framing.jpg';
import alex from '../assets/about/alex.jpg'

const METADATA = {
    title: "Edge Acoustical Systems LLC",
    shortTitle: "Edge Acoustical Systems",
    nickname: "Edge Acoustics",
    about: "At Edge Acoustical Systems, we are dedicated to creating acoustic environments that inspire, comfort, and enhance everyday experiences. With years of expertise in acoustical ceilings, specialty ceilings, drywall solutions, and metal framing, we've established ourselves as industry leaders, consistently delivering innovative and high-quality services. Our team of professionals is committed to providing tailored solutions to meet the unique needs of our clients, transforming spaces into sound-enhanced havens. We take pride in our passion for sound and design, striving for excellence in every project we undertake. Welcome to a world of acoustics where every detail matters, and quality resonates.",
    mission: "Our mission at Edge Acoustical Systems is to lead the industry in acoustic and interior solutions. We are dedicated to providing top-notch services that improve sound quality, aesthetics, and functionality in various spaces. Through innovation, expert craftsmanship, and a commitment to client satisfaction, we aim to create environments where sound is a source of harmony, not disruption. We work tirelessly to meet the specific needs of each project, ensuring the highest standards of quality and performance. Our passion for acoustics drives us to transform spaces and exceed our clients' expectations.",
    vision: "Our vision is to be the forefront provider of acoustic and interior solutions, setting new industry standards for creativity, quality, and performance. We strive to foster an environment where every space is perfectly attuned to its purpose, harmonizing aesthetics, functionality, and sound. We envision a future where our expertise transcends boundaries, enriching homes, businesses, educational institutions, and healthcare facilities with the power of acoustics. We are committed to continuous innovation, sustainable practices, and outstanding service, ensuring that Edge Acoustical Systems remains a trusted partner in creating spaces that resonate with excellence.",
    description: ["Transform Your Space with Expert Acoustics.","At Edge Acoustical Systems, we specialize in creating harmonious environments. With over a decade of experience, our passion for acoustic excellence drives us. Located in the Denver front range area, we're ready to enhance your space. Call us at 720.587.5445 to begin your acoustic journey."],
    hero: "",
    email: "EdgeAcoustical@outlook.com",
    clickableEmail: "mailto:EdgeAcoustical@outlook.com",
    phone: "720.587.5445",
    clickablePhone: "tel:7205875445",
    team: [
      {
        name: 'Alex Avila',
        description: `Meet a dedicated professional with over a decade of experience in the industry. As a father of three girls, he's on a mission to build a better future for his family. Despite his youth, he's committed to creating exceptional acoustic spaces, showcasing expertise, and striving for excellence.`,
        img: alex,
      
      }
    ],
    social: [
        {
          name: 'facebook',
          url: 'https://www.facebook.com/EdgeAcousticSystems/',
        }],

    services: [
        {
          title: 'Acoustical Ceiling',
          description: [
            'Sound-absorbing ceiling installation',
            'Custom ceiling solutions for unique acoustics',
            'Specialized tile installation for noise reduction and aesthetics',
            'Mounting acoustic panels for enhanced sound control',
          ],
          image: acousticCeiling,
        },
        {
          title: 'Specialty Ceiling',
          description: [
            'Unique and innovative ceiling designs',
            'Ornate and decorative ceiling installations',
            'Specialized ceilings for architectural excellence',
            'Expertise in various suspended ceiling systems',
          ],
          image: specialtCeiling,
        },
        {
          title: 'Drywall hanging / finishing',
          description: [
            'Expert drywall installation for construction and renovations',
            'Skilled finishing for a smooth and polished surface',
            'Comprehensive repair services for damaged drywall',
            'Tailored drywall solutions to meet project needs',
          ],
          image: drywall,
        },
        {
          title: 'Metal framing',
          description: [
            'Expert metal stud installation for structural integrity',
            'Installation of metal grids for ceiling systems',
            'Framing solutions for interior walls and ceilings',
            'Framing for exterior structures and architectural features',
          ],
          image: metalFraming,
        },
      ]
}

export default METADATA;