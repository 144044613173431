import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { NavLink as Link  } from "react-router-dom";

export const NavigationContainer = styled(Row)`
  background-color: #FDFDFDff;
  a{
    font-size: 1.4rem;
    font-weight: 600;
    color: #2A639Eff;
  }
  &.bg-light{
    background-color: #FDFDFDff;
    min-height: 106px;
    
  }
  &.navbar-hidden {
    top: -106px;
  }

  .bg-transparent{
    background-color: rgba(255, 255, 255, 0) !important;
    color: white;
    a{
      color: white;
    }
    g{
      fill:white;
    }
  }
`

export const NavigationBar = styled(Navbar)`
  transition: top 0.3s ease;
  // color: #e6dadb;
  padding-right: 2rem;
  padding-left: 2rem;
  
  &.navbar-hidden {
    top: -100px;
  }

  &.bg-light{
    background-color: #FDFDFDff !important;
  }

  .navbar-collapse{
    padding-left: 1rem;
  }
  .navbar-brand{
    margin-right: 0;
  }

`

export const LeftNavigationNav = styled(Nav)`
  // a{
  //   font-size: 1.4rem;
  //   font-weight: 600;
  //   color: #2A639Eff;
  // }
`

export const NavLink = styled(Link)`
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  text-decoration: none;
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
  margin-left: 1rem;
  &.active{
    background: black;
    border-radius: 2rem;
    display: inline-block;
    color: white;
  }
`