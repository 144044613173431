import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import servicesList from './services.data';

import ServiceCard from './components/service-card/service-card.component';
import { PrimarySection } from '../../components/Sections/sections.styles';
import { BigContainer } from '../../components/Containers/containers.styles';

const Services = () => {
    return (
    <PrimarySection>
      <BigContainer>
      <h1 style={{textAlign: "center", fontSize: "3rem"}}>Services</h1>
      <hr/>
      
        <Row>
          {
            servicesList.map((service, index) => {
              return (
                <Col sm={6} lg={4} style={{padding: "1rem"}} key={service.title}>
                  <ServiceCard title={service.title} description={service.description} Img={service.image} key={index}/>
                </Col>
              )
            }
          )}
        </Row> 
        <br/>      
      </BigContainer>
    </PrimarySection>
  );
}

export default Services;