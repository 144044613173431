import acousticCeiling from '../../assets/services/Accoustical Ceilings.jpg';
import specialtCeiling from '../../assets/services/Specialty Cielings.jpg';
import drywall from '../../assets/services/drywall finishing.jpg';
import metalFraming  from '../../assets/services/metal framing.jpg';

const servicesList = [
  {
    title: 'Acoustical Ceiling',
    description: [
      'Sound-absorbing ceiling installation',
      'Custom ceiling solutions for unique acoustics',
      'Specialized tile installation for noise reduction and aesthetics',
      'Mounting acoustic panels for enhanced sound control',
    ],
    image: acousticCeiling,
  },
  {
    title: 'Specialty Ceiling',
    description: [
      'Unique and innovative ceiling designs',
      'Ornate and decorative ceiling installations',
      'Specialized ceilings for architectural excellence',
      'Expertise in various suspended ceiling systems',
    ],
    image: specialtCeiling,
  },
  {
    title: 'Drywall hanging / finishing',
    description: [
      'Expert drywall installation for construction and renovations',
      'Skilled finishing for a smooth and polished surface',
      'Comprehensive repair services for damaged drywall',
      'Tailored drywall solutions to meet project needs',
    ],
    image: drywall,
  },
  {
    title: 'Metal framing',
    description: [
      'Expert metal stud installation for structural integrity',
      'Installation of metal grids for ceiling systems',
      'Framing solutions for interior walls and ceilings',
      'Framing for exterior structures and architectural features',
    ],
    image: metalFraming,
  },
];

export default servicesList;
