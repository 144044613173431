import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import alex from '../../../../assets/about/alex.jpg'


const IMG = {
    'Alex Avila' : alex,
}

const TeamMemberCard = (props) => {
    const { name, description } = props
    return (
        <Row>
            <Col>
              <Card style={{backgroundColor:"#2A639Eff", color:"#FDFDFDff"}}>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Figure>
                        <Figure.Image
                          width={500}
                          height={500}
                          alt="Family"
                          src={IMG[name]}
                          style={{backgroundColor:"black", padding:"0.7rem", borderRadius: "5%"}}
                        />
                      </Figure>
                    </Col>
                    <Col md={6}>
                      <Card.Title>{name}</Card.Title>
                      <Card.Text>
                        {description}
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
        </Row>
  );
}

export default TeamMemberCard;