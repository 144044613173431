import { Outlet, Link } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Footer from "../footer/footer.route";
import { ReactComponent as Logo } from "../../assets/logo_short.svg";
import { NavigationBar, LeftNavigationNav, NavLink, NavigationContainer } from "./navigation.styles.jsx";
import METADATA from "../../data/data";

const Navigation = (props) => {
  const { bgTop, bgScroll } = props;
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(true);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true); // Add state for collapsing the navbar

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const atTop = currentScrollPos <= 10;
      setScrolled(prevScrollpos > currentScrollPos && !atTop);
      setVisible(prevScrollpos > currentScrollPos || atTop);
      setPrevScrollpos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollpos]);

  const handleNavCollapse = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
    if (window.innerWidth > 992) return;
    setIsNavCollapsed(!isNavCollapsed); // Toggle the navbar collapse state
  };

  return (
    <Fragment>
      <NavigationContainer className={bgTop == 'light' ? 'bg-light' : ''}>
        <NavigationBar
          bg={scrolled || window.innerWidth < 992 ? bgScroll : bgTop}
          expand="lg"
          fixed="top"
          className={visible ? '' : 'navbar-hidden'}
        >
          <NavigationBar.Brand>
            <Link to="/">
                <Logo width="100" height="80" alt="Logo"/>
            </Link>
          </NavigationBar.Brand>
          <h5 style={{width: "144px", textAlign:"center" }}>{METADATA.title}</h5>
          <NavigationBar.Toggle
            aria-controls="navbar-nav"
            onClick={handleNavCollapse} // Handle navbar collapse on button click
          />
          <NavigationBar.Collapse id="navbar-nav" in={!isNavCollapsed}>
            <LeftNavigationNav className="me-auto">
              <NavLink to="/" onClick={handleNavCollapse}>Home</NavLink>
              <NavLink to="/services" onClick={handleNavCollapse}>Services</NavLink>
              <NavLink to="/about" onClick={handleNavCollapse}>About</NavLink>
              <NavLink to="/contact" onClick={handleNavCollapse}>Contact</NavLink>
            </LeftNavigationNav>
          </NavigationBar.Collapse>
        </NavigationBar>
      </NavigationContainer>
      <Row>
        <Outlet />
      </Row>
      <Footer />
    </Fragment>
  );
};

export default Navigation;
