import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure'

import { MainContainer } from '../../../../components/Containers/containers.styles';

import descriptionImg from '.././../../../assets/landing/hero3.jpg'

import METADATA from '../../../../data/data'

const Description = () => {
    return (
        <MainContainer>
            <Row>
                <Col lg={5} md={6}>
                    <Figure style={{padding: "0.7rem", borderRadius: "5%", backgroundColor:"black", margin:"0"}}>
                        <Figure.Image src={descriptionImg} style={{borderRadius: "6%", backgroundColor:"black", margin:"0", position:"relative", zIndex: 1}}/>
                    </Figure>
                </Col>
                <Col lg={1} md={1}><br/></Col> 
                <Col lg={6} md={5}>
                    {
                        METADATA.description.map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                          ))
                    }
                    <h5>Stay Connected Throughout Your Project</h5>
                    <span>
                        We make it a priority to maintain open lines of communication with our clients throughout the entire 
                        project, from start to finish. If you'd like a complimentary quote or have any inquiries or specific 
                        requests, please don't hesitate to reach out. We're eager to be of service to you!
                    </span>

                </Col>
            </Row>
        </MainContainer>
    )
}

export default Description;