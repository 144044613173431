import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Img from '../../../../assets/contact/contact-img.jpg';

const ServiceCard = ({ title, description, Img }) => {
  return (
    <Card className="service-card" style={{backgroundColor: "#2A639Eff", color:"#FDFDFDff", border: "solid 0.5px #262626"}}>
      <Card.Body>
      <Card.Title>{title}</Card.Title>
        <Card.Img src={Img} style={{backgroundColor:"black", padding:"0.7rem", borderRadius: "5%"}}/>  
          <br/>
          <br/>
          <strong>Key Offering:</strong>
          <ul>
            {description.map((subService, index) => (
              <li key={index}>{subService}</li>
            ))}
          </ul>
      </Card.Body>
    </Card>
  );
};

export default ServiceCard;
