import styled from "styled-components";

export const PrimarySection = styled.div`
    background-color: #FDFDFDff;
    color: #020203ff;
`

export const SecondarySection = styled.div`
    background-color: #2A639Eff; 
    color: #FDFDFDff;
`

export const TertiarySection = styled.div`
    background-color: #D1D1D1;
    color: #020203ff;
`