import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import TeamMemberCard from './components/team-member-card/team-member-card.component.jsx'
import { PrimarySection, SecondarySection, TertiarySection  } from '../../components/Sections/sections.styles.jsx'
import { BigContainer } from '../../components/Containers/containers.styles.jsx'

import familyImg from '../../assets/about/Family_Portrait.jpg'

import METADATA from '../../data/data.jsx'

const About = () => {
    return (
    <>
      <SecondarySection style={{textAlign:"center"}}>
        <BigContainer>
          <div style={{ position: "relative" }}>
            <Figure>
              <Figure.Image
                width={650}
                height={300}
                alt="Family"
                src={familyImg}
                style={{backgroundColor:"black", padding:"0.7rem", borderRadius: "5%"}}
              />
            </Figure>
            <div style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
              opacity: "0.5"
            }}>
              Photo by a4gpa: <a href="https://www.flickr.com/photos/94833286@N00/1573456981" target="_blank" rel="noopener noreferrer" style={{color:"inherit"}}>source</a>
            </div>
          </div>
        </BigContainer>
      </SecondarySection>
      <TertiarySection>
        <BigContainer>
          <Row>
            <Col>
              <h1>About Us</h1>
              <hr/>
              <p>
              {METADATA.about}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Our Mission</h2>
              <hr/>
              <p>
              {METADATA.mission}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Our Vision</h2>
              <hr/>
              <p>
              {METADATA.vision}
              </p>
            </Col>
          </Row>
        </BigContainer>
      </TertiarySection>
      <PrimarySection>
        <BigContainer>
          <h2>Our Team</h2>
          <hr/>
          {
            METADATA.team.map(({name, description, img}) => <TeamMemberCard 
              name = {name}
              description = {description}
              image = {img}
              key = {name}
            />)
          }
          
          <br/>
        </BigContainer>
      </PrimarySection>
    </>
  );
}

export default About;