import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MainContainer } from '../../../../components/Containers/containers.styles';

const Filler = () => {
    return (
        <MainContainer>
            <Row>
                <Col sm={6}>
                    <h5>On-time Completion</h5>
                    <p>
                        We will work with you to meet deadlines and coordinate with other related projects. We will maintain open 
                        communication with you to keep you up to date on the status of your job. We do this to guarantee that the 
                        project is completed according to your preferences.
                    </p>
                </Col>
                <Col sm={6}>
                    <h5>Great Service</h5>
                    <p>
                    We combine our industry knowledge, the highest quality building supplies and equipment, and our dedication to 
                    deliver exceptional service to our clients. We will stay in touch, keeping you up to date on both the paperwork 
                    and renovation process
                    </p>
                </Col>
            </Row>
        </MainContainer>
    )
}

export default Filler;