// import HeroSection from "./components/hero-section/hero-section.component";
import HeroSection from "./components/hero-section/hero-section-slideshow.component";
import Description from "./components/description/description.component";
import ContactForm from "./components/contact/contact.component";

import './landing.styles.jsx'
import Filler from "./components/filler/filler.component";
import { PrimarySection, SecondarySection, TertiarySection } from "../../components/Sections/sections.styles";


const Landing = () => {
  return (
    <>
      <HeroSection/>

      <PrimarySection>
        <Description/>
      </PrimarySection>

      <SecondarySection>
      <Filler/>
      </SecondarySection>
      
      <TertiarySection>
        <ContactForm/>
      </TertiarySection>
    </>

  );
};

export default Landing;