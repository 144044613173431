import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';

import ContactForm from '../landing/components/contact/contact.component';
import ContactImg from '../../assets/contact/contact-img.jpg'
import { PrimarySection, SecondarySection, TertiarySection } from '../../components/Sections/sections.styles';
import { BigContainer } from '../../components/Containers/containers.styles';

import METADATA from '../../data/data';

const Contact = () => {
    return (
    <>
      <SecondarySection>
        <BigContainer>
          <Row>
            <Col sm={6}>
              <h3>Get A Free Quote</h3>
              <p>
                We prioritize maintaining continuous communication with our clients from the project's 
                inception to its completion. If you require a free quote or have any questions or specific 
                needs, please feel free to contact us. We're enthusiastic about assisting you!
              </p>
              <h3>Edge Acoustical Systems</h3>
              <p>Phone - <a href={METADATA.clickablePhone} style={{color:"inherit", fontSize:"1.25rem"}}>{METADATA.phone}</a></p>
              <p>Email - <a href={METADATA.clickableEmail} style={{color:"inherit", fontSize:"1.25rem"}}>{METADATA.email}</a></p>
              </Col>
              <Col sm={6}>
              <br/>
              <div style={{ position: "relative" }}>
                <Figure style={{ outline: "black solid 10px", borderRadius: "5%", backgroundColor: "black", margin: "0" }}>
                  <Figure.Image src={ContactImg} style={{ borderRadius: "5%", backgroundColor: "black", margin: "0" }} />
                </Figure>
                <div style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  opacity: "0.5"
                }}>
                  Photo by Anete Lusina: <a href="https://www.pexels.com/photo/instruments-prepared-for-measurements-in-house-4792479/" target="_blank" rel="noopener noreferrer" style={{color:"inherit"}}>source</a>
                </div>
              </div>
            </Col>
          </Row>
        </BigContainer>
      </SecondarySection>
      <TertiarySection>
        <ContactForm />
      </TertiarySection>
    </>
  );
}

export default Contact;